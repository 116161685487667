import { api } from '@/assets/js/api';
import { patch, post } from '@/services/items';
import { postEvent } from '@/services/events';

import {
    Approve,
    ApproveComment,
    AfterFileApprove,
} from '@/components/clients/khb.ivak.nl/handbook/approveMixin';

function patchMaintenanceJob({ id, report }) {
    const jobData = {
        date_executed: Math.floor(Date.now() / 1000),
        report,
    };
    return patch({
        endpoint: `items/maintenance_jobs/${id}/`,
        data: jobData,
    });
}

function patchMaintenanceTasks(tasks) {
    const nowTimestamp = Math.floor(Date.now() / 1000);
    function getTimestampWithInterval(interval_weeks) {
        const now = new Date();
        now.setDate(now.getDate() + interval_weeks * 7);
        return Math.floor(now.getTime() / 1000);
    }

    const patchData = tasks.map((task) => ({
        id: task.id,
        last_executed: nowTimestamp,
        due_next: getTimestampWithInterval(task.interval_weeks),
    }));

    return patch({
        endpoint: 'items/maintenance/',
        data: patchData,
    });
}

function postJobEvents({ report, tasks, practice }) {
    return new Promise((resolve, reject) => {
        const promises = [];
        tasks.forEach((task) => {
            promises.push(
                postEvent({
                    event: 'custom',
                    subtype: '',
                    trigger_type: 'blixem',
                    name: 'Onderhoud uitgevoerd',
                    linked_table: 'maintenance',
                    linked_item: task.id,
                    data: {
                        message: '',
                        attachments: report ? [{ link: report, filename: 'Onderhoudsrapport' }] : [],
                    },
                    endpoint: window.location.href,
                    timestamp: Math.floor(Date.now() / 1000),
                    practice: [practice],
                }),
            );
        });

        Promise.all(promises)
            .then(resolve)
            .catch(reject);
    });
}
const custom_client = {
    default: {
        methods: {
            async onMaintenanceComplete(input) {
                const data = input.data.form;

                await patchMaintenanceTasks(data.tasks);
                await patchMaintenanceJob(data);
                await postJobEvents(data);
                return true;
            },
        },
    },
    login: {
        methods: {
            onBeforeSetUser(user) {
                if (user.filter?.item?.id !== 1 && [1, 2].includes(user.role)) {
                    // 1 = admin, 2 = coach
                    user.role = 5; // 5 = coach-editor
                }
                return user;
            },
        },
    },

    Items: {
        methods: {
            onRouteChange(val) {
                if (val === '/practices') {
                    const user = this.$store.getters['Auth/user'];
                    if (
                        ![1, 2].includes(user.role) // 1 = admin, 2 = coach
                        && user.filter?.item?.id
                    ) {
                        if (user.filter.item.id !== 1) {
                            this.$router.push({
                                name: 'practices-item',
                                params: { id: user.filter.item.id },
                            });
                        }
                    }
                }
            },
        },
        watch: {
            '$route.path': {
                handler: 'onRouteChange',
                immediate: true,
            },
        },
    },

    Editpane: {
        methods: {
            onSaveAttachment(data) {
                const user = this.$store.getters['Auth/user'];
                const coaches = user.filter?.item?.linked_coaches;

                if (!data.item.name) {
                    this.$toast({
                        msg: this.$t('toasts.name_required'),
                        classes: 'bg-danger',
                    });
                    return Promise.reject();
                }
                if (!data.item.file) {
                    this.$toast({
                        msg: this.$t('toasts.file_required'),
                        classes: 'bg-danger',
                    });
                    return Promise.reject();
                }
                if (data.item.file && data.item.file !== data.item.file_approved) {
                    // Has coaches -> approval needed
                    if (coaches && coaches.length > 2) {
                        if (!data.item.attachment && !data.item.notify_coach) {
                            data.item.approved = 1;
                            data.item.notified = 1;
                            data.item.file_approved = data.item.file;
                        } else {
                            data.item.approved = 0;
                            data.item.notified = 0;
                        }
                    } else {
                        // No coaches -> instantly approved
                        data.item.approved = 1;
                        data.item.notified = 1;
                        data.item.file_approved = data.item.file;
                    }
                }
                if (data.item.attachment && data.item.attachment.id) {
                    data.item.step = 0;
                    data.item.page = 0;
                }

                return Promise.resolve(data);
            },

            onSaveHandbookPage(data) {
                const user = this.$store.getters['Auth/user'];
                let newData;
                if (user.filter && user.filter.item && user.filter.item.id !== 1) {
                    const practiceSteps = data.item.steps.map((step) => ({
                        id: step.id,
                        steps: step.steps,
                    }));
                    newData = {
                        item: {
                            id: data.item.id,
                            steps: practiceSteps,
                        },
                    };
                } else {
                    newData = data;
                }
                return new Promise((resolve) => {
                    if (this.storeddata.practiceFilesChanges) {
                        const requests = [];
                        if (
                            this.storeddata.practiceFilesChanges.remove
                            && this.storeddata.practiceFilesChanges.remove.length
                        ) {
                            // perform removes
                            const request = api.delete({
                                name: 'delete_attachments',
                                endpoint: `items/files_2/${this.storeddata.practiceFilesChanges.remove.join(
                                    ',',
                                )}`,
                                loading: true,
                            });
                            requests.push(request);
                        }

                        if (
                            this.storeddata.practiceFilesChanges.patch
                            && this.storeddata.practiceFilesChanges.patch.length
                        ) {
                            // perform patches
                            this.storeddata.practiceFilesChanges.patch.forEach((file, index) => {
                                const method = file.id > 1000000000 ? 'post' : 'patch';
                                const request = api[method]({
                                    name: `post_file_${index}`,
                                    endpoint: `items/files_2/${method === 'post' ? '' : `${file.id}/`
                                        }`,
                                    data: file,
                                });
                                requests.push(request);
                            });
                        }

                        Promise.all(requests)
                            .then(() => {
                                resolve(newData);
                            })
                            .catch((err) => {
                                console.error(err);
                                resolve(newData);
                            });
                    } else {
                        resolve(newData);
                    }
                });
            },

            async onSavePDCACycle(data) {
                const {
                    name,
                    status,
                    date_done_notified,
                    tasks_status_date_executed,
                    created,
                    id,
                    practice,
                    pdca_tasks,
                } = data.item;

                if (status === 'closed') {
                    return data;
                }

                const emails = [];

                const emailEvent = {
                    linked_table: 'pdca',
                    linked_item: id,
                    custom_parameters: {
                        practice,
                    },
                };
                const emailButton = {
                    text_button: 'Open kwaliteitscyclus',
                    text_url: `https://khb.ivak.nl/pdca/item/${id}/`,
                };

                // New task notifications
                const new_tasks = pdca_tasks?.[0]?.items?.filter(task => task.id > 999999999 && task.responsible_user?.id) || [];
                const newTaskProcessedUsers = [];
                if (new_tasks.length) {
                    const r = await this.$modal({
                        id: 'confirm',
                        path: 'Confirm',
                        title: 'Notificaties verzenden voor nieuwe taken',
                        data: {
                            text: 'Wilt u de verantwoordelijke gebruikers op de hoogte brengen van de nieuw toegevoegde taken?',
                            buttonText1: this.$t('actions.yes'),
                            buttonText2: this.$t('actions.no'),
                            buttonClass1: 'bg-success',
                            button1Resolve: true,
                            buttonShortcuts1: ['y', 'enter'],
                            buttonShortcuts2: ['n', 'esc'],
                        },
                    }).catch(() => false);

                    if (r !== false) {
                        new_tasks.forEach((task) => {
                            if (task.responsible_user?.email) {
                                if (newTaskProcessedUsers.includes(task.responsible_user.id)) return;
                                newTaskProcessedUsers.push(task.responsible_user.id);

                                emails.push({
                                    to: [
                                        { name: task.responsible_user.name, email: task.responsible_user.email },
                                    ],
                                    subject: 'Nieuwe taak in kwaliteitscyclus.',
                                    template: {
                                        path: 'message.mjml',
                                        data: {
                                            data: {
                                                title: 'Nieuwe taak in kwaliteitscyclus.',
                                                text: `In de cyclus ${name} is een nieuwe taak toegevoegd die aan jou is toegewezen. Klik op de knop om de taak te bekijken.`,
                                                ...emailButton,
                                            },
                                        },
                                    },
                                    event: emailEvent,
                                });
                            }
                        });
                    }
                }

                // All tasks done notifications
                let done_mail_sent = false;
                if (date_done_notified === 0 && tasks_status_date_executed?.done_tasks > 0 && tasks_status_date_executed.done_tasks === tasks_status_date_executed.total_tasks) {
                    // all tasks done, send email, set notified date.
                    emails.push({
                        to: [
                            { user: created.user.id },
                        ],
                        subject: 'Alle taken uitgevoerd in kwaliteitscyclus.',
                        template: {
                            path: 'message.mjml',
                            data: {
                                data: {
                                    title: 'Alle taken uitgevoerd in kwaliteitscyclus.',
                                    text: `In de cyclus ${name} zijn alle taken uitgevoerd. Klik op de knop om de cyclus te openen.`,
                                    ...emailButton,
                                },
                            },
                        },
                        event: emailEvent,
                    });

                    done_mail_sent = true;
                }

                if (emails.length > 0) {
                    await post({
                        endpoint: 'email/',
                        data: emails,
                    }).catch((err) => {
                        this.$toast({ msg: 'Email-notificatie sturen mislukt', classes: 'bg-danger' });
                        console.error(err?.message);
                        throw err;
                    });

                    if (done_mail_sent) {
                        const now = Math.floor(Date.now() / 1000);
                        data.item.date_done_notified = now;
                    }
                }

                return data;
            },

            onApprove({ approve, data }) {
                this.Approve(approve, { type: 'file', data: data.item });
            },

            Approve,
            ApproveComment,
            AfterFileApprove,
        },
    },

    Fields: {
        methods: {
            onMaintenanceUpdate({ data }) {
                const lastDate = data.item.last_executed;
                const interval = data.item.interval_weeks;
                if (typeof lastDate === 'number' && typeof interval === 'number') {
                    const addToDate = interval * 7 * 86400;
                    this.$set(data.item, 'due_next', lastDate + addToDate);
                } else {
                    this.$set(data.item, 'due_next', undefined);
                }
            },

            onPDCAReminderDateChange({ data }) {
                data.item.date_notified = 0;
            },
        },
    },
};

export default custom_client;
