import Vue from 'vue';
import Vuex from 'vuex';
import get from 'lodash.get';
import { get as getAPI } from '@/services/items';

// OPTIMIZE localstorage store sync
// OPTIMIZE cross user sync
import Auth from '@/store/Auth';
import Actions from '@/store/Actions';
import Documents from '@/store/Documents';
import Errors from '@/store/Errors';
import Rights from '@/store/Rights';
import Login from '@/store/Login';
import i18n from '@/2/services/language';

import vibrateNotification from '@/2/services/vibrate';
import * as localStore from '../assets/helpers/localStore';
import { build_routes } from '../router/build_routes';
import router, { resetRouter } from '../router';
import { findInCats } from '../services/categories';

Vue.use(Vuex);

const apiUrl = process.env.NODE_ENV === 'development' && !window.location.hostname.startsWith('live.') ? process.env.VUE_APP_API_URL_DEV : process.env.VUE_APP_API_URL;

const store = new Vuex.Store({
    modules: {
        Actions,
        Auth,
        Documents,
        Errors,
        Rights,
        Login,
    },
    state: {
        config: {},
        app: {
            languages: [
                { id: 'nl', name: 'Nederlands' },
                { id: 'en', name: 'English' },
            ],
            loading_fullscreen: {
                show: false,
                title: '',
                message: '',
                names: [],
            },
            env: process.env.NODE_ENV,
            name: 'Blixem',
            name_separator: ' 🗲 ',
            size: '',
            version: APPLICATION_VERSION,
        },
        api: {
            url: `https://${apiUrl}/${process.env.VUE_APP_API_VERSION}/`,
            file_location: '',
            headers: {
                'app-version': APPLICATION_VERSION,
                namespace: '',
                Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
            },
            timeout: process.env.NODE_ENV === 'development' ? 60 : 15,
        },
        local: {
            data: {},
        },
        settings: {},
        imageCrop: {
            enabled: false,
            config: {},
            resolve: null,
            reject: null,
        },
    },
    mutations: {
        loadStart(state, name) {
            state.app.loading_fullscreen.names.push(name);
            state.app.loading_fullscreen.show = true;
        },

        loadEnd(state, name) {
            const loader = state.app.loading_fullscreen;
            const index = loader.names.indexOf(name);
            if (index !== -1) {
                loader.names.splice(index, 1);
            }

            if (!loader.names.length) {
                loader.show = false;
                loader.title = '';
                loader.message = '';
            }
        },

        setAppData(state, config) {
            if (config.settings) {
                state.settings = config.settings;
                if (config.settings.cdn) {
                    if (process.env.NODE_ENV === 'development') {
                        state.api.file_location = `https://${process.env.VUE_APP_API_URL}/`;
                    } else {
                        state.api.file_location = config.settings.cdn;
                    }
                }
                state.app.name = config.settings.company_name || 'Blixem';
            }
        },

        setNamespace(state, namespace) {
            state.api.headers.namespace = namespace;
            if (namespace.includes('.')) {
                state.app.name_separator = ' - ';
            }
        },

        setConfig(state, { config, lang }) {
            if (config) {
                state.config = config;
            }
            if (lang) {
                state.config.current = state.config[lang];
            }
        },

        set_size(state, payload) {
            state.app.size = payload;
        },

        remove_template(state, { table, id } = {}) {
            if (table && id && state.config.current.templates[table]) {
                Object.values(state.config).forEach((value) => {
                    value.templates[table] = value.templates[table].filter((it) => it.id !== id);
                });
            }
        },

        localStore(state, data) {
            Vue.set(state.local, 'data', data);
        },

        openImageCropper(state, { config = {}, resolve, reject }) {
            state.imageCrop.config = config;
            state.imageCrop.resolve = resolve;
            state.imageCrop.reject = reject;
            state.imageCrop.enabled = true;
        },

        closeImageCropper(state) {
            state.imageCrop.config = {};
            state.imageCrop.resolve = null;
            state.imageCrop.reject = null;
            state.imageCrop.enabled = false;
        },
    },
    actions: {
        setAppData({ state, commit, dispatch }, { config, lang = i18n.locale } = {}) {
            return new Promise((resolve, reject) => {
                let conf;
                if (config) {
                    conf = config;
                    commit('setConfig', { config: conf, lang });
                } else {
                    conf = state.config;
                }

                if (!conf[lang]) {
                    lang = 'nl';
                }
                conf = conf[lang];
                if (!config) {
                    commit('setConfig', { lang });
                }

                dispatch('localRestore');

                if (conf) {
                    if (conf.settings || conf.auth) {
                        commit('setAppData', conf);
                    }
                    if (conf.documents) {
                        dispatch('Documents/set', { config: state.config, key: 'documents' });
                    }
                    if (conf.roles) {
                        dispatch('Rights/setRoles', { roles: conf.roles });
                    }
                    if (conf.pages && router.currentRoute.meta?.editpane?.api?.endpoint !== 'categories') {
                        const routes = build_routes(conf.pages);
                        resetRouter();
                        routes.forEach((route) => router.addRoute(route));
                    }
                    resolve(conf);
                } else {
                    console.debug('No config available in data');
                    reject(new Error('No config available in data'));
                }
            });
        },

        getConfig({ dispatch }, params = '') {
            return new Promise((resolve, reject) => {
                getAPI({
                    endpoint: 'configuration/',
                    data: params,
                })
                    .then((r) => {
                        dispatch('setAppData', { config: r }).then(() => {
                            resolve();
                        });
                    })
                    .catch((err) => {
                        if (!window.location.pathname.startsWith('/web')) {
                            Vue.toast('Could not fetch config from API');
                        }
                        reject(err);
                    });
            });
        },

        localStore({ commit, state }, data) {
            const newData = localStore.update(data, state.local.data);
            commit('localStore', { ...newData });
            localStore.store(newData);
        },

        localRestore({ commit }) {
            localStore.restore().then((data) => {
                if (data) {
                    commit('localStore', data);
                }
            }).catch(() => {
                Vue.toast('Can\'t use localStorage. Please update your browsers settings.');
            });
        },

        async playSound(context, settings) {
            const { sound, vibrate = true } = settings;

            function timeout(ms) {
                return new Promise((resolve) => { setTimeout(resolve, ms); });
            }

            if (sound) {
                const path = sound.startsWith('http') ? sound : `/sound/${sound}.mp3`;
                if (window.audio_notification) {
                    window.audio_notification.setAttribute('src', path);
                } else {
                    window.audio_notification = new Audio(path);
                }
                await timeout(150);
                try {
                    window.audio_notification.play().catch((e) => { console.debug('Audio error', e); });
                    if (vibrate) { vibrateNotification(vibrate === true ? sound : vibrate); }
                } catch (e) {
                    console.debug(e);
                }
            }
        },
    },
    getters: {
        cats: (state) => ({
            group,
            type = 'map', // map or array
            search = false, // { val: 'id or type', query: ['> confirmed', '< sent' (operator + id/type)] }
            defaultOutput,
        }) => {
            if (group === 'lang') {
                return state.app.languages;
            }
            return findInCats({
                categories: state.config?.current?.categories || {},
                group,
                type,
                search,
                defaultOutput,
                lang: i18n.locale,
            });
        },

        categoryLevel: (state) => ({ type, id, level }) => {
            const cat = state.config.current.categories[type];
            if (cat) {
                const item = cat.items_map && cat.items_map[id];
                if (item && +item.level === +level) {
                    // has needed level
                    return item;
                }
                // search for parent
                const index = cat.items.findIndex((it) => it.id === id);
                if (index > -1) {
                    for (let i = index; i > -1; i -= 1) {
                        if (+cat.items[i].level === +level) {
                            return cat.items[i];
                        }
                    }
                }
            }
            return false;
        },

        cdn: (state) => state.api.file_location,
        cdnpath: (state, getters) => (path) => {
            if (path) {
                if (path.startsWith('http')) { return path; }
                return `${getters.cdn}${path || ''}`;
            }
            return undefined;
        },

        currency: (state) => state.settings.currency || 'EUR',

        data: (state) => (path) => {
            const data = get(state, path);
            if (data) {
                return data;
            }
            return false;
        },

        namespace: (state) => state.api.headers.namespace,

        companyName: (state) => state.settings.company_name,

        settings: (state) => state.settings || {},

        // TODO cleanup table_routes
        // table_routes: (state) => (state.config.current?.table_routes || {}),
        table_routes: (state) => {
            const routes = (state.config.current?.table_routes || state.config.current?.tableRoutes || {});
            return Object.keys(routes).reduce((acc, key) => {
                if (typeof routes[key] === 'string') {
                    acc[key] = {
                        path: routes[key],
                        name: '',
                    };
                } else {
                    acc[key] = routes[key];
                }
                return acc;
            }, {});
        },

        languages: (state) => state.settings.languages || false,

        defaultValues: (state, getters) => (table) => {
            const defaults = getters.settings && getters.settings.default_values;
            if (defaults && defaults[table]) {
                return defaults[table];
            }
            return false;
        },

        size: (state) => state.app.size,

        apiUrl: (state) => state.api.url,

        apiConfig: (state, getters) => {
            const data = { ...state.api };
            if (getters['Auth/isAuthenticated']) {
                data.headers = {
                    ...data.headers,
                    Authorization: `Bearer ${getters['Auth/token']}`,
                };
            }
            return data;
        },

        localStore: (state) => (page, component) => localStore.get(state.local.data, { page, component }),
    },
});

export default store;

export const useStore = () => store;
