import difference from 'lodash.difference';
import { recursiveChildFunction } from '@/services/helpers-ts';
import { getConflicts } from '@/mixins/availability/global';

export const availabilityStateParams = {
    INITIALIZED: 'initialized',
    NODATES: 'no-dates',
    CALCULATED: 'calculated',
}

const availabilityFunctions = {
    methods: {
        setAvailabilityState(state) {
            this.$emit('action', {
                method: 'onUpdateData',
                payload: { store: true, key: 'availabilityState', data: state },
            });
        },
        initProjectAvailability(periods) {
            this.setAvailabilityState(availabilityStateParams.INITIALIZED);

            // Get availability for booking
            if (Object.keys(periods).length) {
                this.listItemsPerPeriod()
                    .then(this.fetchAvailability)
                    .then(this.calculateAvailabilityConflicts)
                    .catch((err) => { console.debug(err); });
            } else {
                this.setAvailabilityState(availabilityStateParams.NODATES);
            }

            // Setup watchers
            // Period watchers, to trigger availability-recheck
            if (this.availability.keys.periodsInItem) { // Periods
                this.$watch('data.periodDates', this.periodDatesChanged);
            } else { // Regular items
                this.$watch(`item.${this.availability.keys.fromdate}`, this.periodDatesChanged);
                this.$watch(`item.${this.availability.keys.todate}`, this.periodDatesChanged);
            }

            // General availability watcher
            this.$watch('containsUnavailableItems', this.availableStateChanged, { immediate: true });

            // Listen to line update watchers
            this.$on('changed', (payload = {}) => {
                if (payload.action) {
                    switch (payload.action) {
                        case 'delete-line':
                            setTimeout(() => {
                                this.listItemsPerPeriod('delete').then(this.calculateAvailabilityConflicts);
                            }, 1000);
                            break;

                        case 'checklist-amount-updated':
                            this.listItemsPerPeriod('update', payload.period).then(this.calculateAvailabilityConflicts);
                            break;

                        case 'add-line':
                        case 'add-checklist':
                        case 'change-line': {
                            if (payload.type === 'product') {
                                const newProductIDsInLine = difference(payload.val, payload.oldVal);
                                if (newProductIDsInLine.length) {
                                    const newProductIDs = this.addNewProductIDsToPeriod(payload.periodID, newProductIDsInLine);
                                    this.listItemsPerPeriod('update', payload.periodID);
                                    if (newProductIDs.length) {
                                        // Fetch data for new products in period
                                        this.fetchSelectedAvailability(payload.periodID, newProductIDs)
                                            .then(this.calculateAvailabilityConflicts)
                                            .catch((err) => { console.debug(err); });
                                    } else {
                                        // Update itemamounts and recalculate conflicts
                                        this.calculateAvailabilityConflicts();
                                    }
                                } else {
                                    this.listItemsPerPeriod('update', payload.periodID)
                                        .then(this.calculateAvailabilityConflicts);
                                }
                            } else if (payload.type === 'amount') {
                                // Update itemamounts and recalculate conflicts
                                this.listItemsPerPeriod('update', payload.periodID)
                                    .then(this.calculateAvailabilityConflicts);
                            } else if (payload.type === 'period' || ['add-line', 'add-checklist'].includes(payload.action)) {
                                const addToMissingItems = (periodid, productid, data) => {
                                    if (!data[periodid]) {
                                        data[periodid] = new Set([productid]);
                                    } else {
                                        data[periodid].add(productid);
                                    }
                                };

                                const findMissingAvailabilityDataForPeriods = () => new Promise((resolve) => {
                                    const missingItems = {};
                                    Object.entries(this.availability.periods).forEach(([periodid, perioddata]) => {
                                        [...perioddata.items.keys()].forEach((productid) => {
                                            if (
                                                !this.availability.items[productid]
                                                || !this.availability.items[productid].periods[periodid]
                                            ) {
                                                addToMissingItems(periodid, productid, missingItems);
                                            }
                                        });
                                    });
                                    resolve(missingItems);
                                });

                                let periodKey = !this.availability.keys.periodInLine ? 'change-allperiods' : payload.item[this.availability.keys.periodInLine];
                                let actionValue = 'update';
                                if (periodKey === 'change-allperiods') {
                                    periodKey = this.item.id;
                                    actionValue = 'change-allperiods';
                                }
                                if (periodKey) {
                                    this.listItemsPerPeriod(actionValue, periodKey)
                                        .then(findMissingAvailabilityDataForPeriods)
                                        .then((r) => {
                                            if (Object.keys(r).length) {
                                                Object.entries(r).forEach(([periodid, productIDs]) => {
                                                    this.fetchSelectedAvailability(periodid, productIDs)
                                                        .then(this.calculateAvailabilityConflicts)
                                                        .catch(console.debug);
                                                });
                                            } else {
                                                this.calculateAvailabilityConflicts();
                                            }
                                        });

                                    if (payload.type === 'period' && payload.oldVal && payload.val !== payload.oldVal) {
                                        this.listItemsPerPeriod('update', payload.oldVal);
                                    }
                                }
                            }
                            break;
                        }

                        default:
                    }
                }
            });
        },

        updateAvailabilityForExistingItems() {
            return this.fetchAvailability()
                .then(this.calculateAvailabilityConflicts)
                .catch((e) => { console.debug(e); });
        },

        listItemsPerPeriod(action = 'init', period = 0) {
            return new Promise((resolve) => {
                this.periodClearItems(action, period);

                // Add unique item-ids to periods
                recursiveChildFunction(this.groups, 'groups', (group) => {
                    group.items.forEach((item) => {
                        this.periodAddItems(item, period);
                    });
                });

                resolve();
            });
        },

        calculateAvailabilityConflicts() {
            this.$set(
                this.availability,
                'conflicts',
                getConflicts(this.availability.items, this.availability.periods),
            );
            this.setAvailabilityState(availabilityStateParams.CALCULATED);
        },

        availableStateChanged(newv, oldv) {
            if (JSON.stringify(newv) !== JSON.stringify(oldv)) {
                const newTabValue = { ...this.segment.tab };
                newTabValue.icon = newv.length ? 'alert color-danger' : false;

                this.$emit('action', {
                    method: 'onUpdateData',
                    payload: { segment: this.segment.slug, key: 'tab', data: newTabValue },
                });
                this.$emit('action', {
                    method: 'onUpdateData',
                    payload: { store: true, key: 'periodsWithConflicts', data: newv },
                });
            }
        },

        async periodDatesChanged(newv, oldv = {}) {
            if (newv) {
                const changed = [];
                if (typeof newv === 'number') { // booking without periods
                    if (Object.keys(this.availability.periods).length === 0 || !this.availability.periods[this.item.id]) {
                        await this.setAvailabilityPeriods();
                        await this.listItemsPerPeriod();
                    }
                    if (this.availability.periods[this.item.id]) {
                        this.availability.periods[this.item.id].from = this.item[this.availability.keys.fromdate];
                        this.availability.periods[this.item.id].to = this.item[this.availability.keys.todate];

                        changed.push(this.item.id);
                    }
                } else { // booking with periods
                    Object.keys(newv).forEach((periodid) => {
                        if (!oldv[periodid] || oldv[periodid] !== newv[periodid]) {
                            changed.push(periodid);
                            const newDates = newv[periodid].split('-');
                            if (this.availability.periods[periodid]) {
                                this.availability.periods[periodid].from = Number(newDates[0]);
                                this.availability.periods[periodid].to = Number(newDates[1]);
                            } else {
                                this.$set(this.availability.periods, periodid, {
                                    id: periodid,
                                    from: Number(newDates[0]),
                                    to: Number(newDates[1]),
                                    items: new Map(),
                                    overlappingPeriods: {},
                                });
                            }
                            const periods = this.calculateOverlappingPeriods(this.availability.periods);
                            this.$set(this.availability, 'periods', periods);
                        }
                    });
                }
                if (changed.length) {
                    console.debug('Availability; period days changed for period(s)', changed);
                    this.setAvailabilityState(availabilityStateParams.INITIALIZED);
                    this.fetchAvailability({ periods: changed })
                        .then(this.calculateAvailabilityConflicts)
                        .catch((err) => { console.debug(err); });
                }
            } else if (!newv && typeof oldv === 'number') {
                this.$set(this.availability, 'conflicts', {});
                this.setAvailabilityState(availabilityStateParams.NODATES);
            }
        },
    },

    computed: {
        containsUnavailableItems() {
            if (!this.availability || !Object.keys(this.availability.conflicts).length) {
                return [];
            }
            return Object.keys(this.availability.conflicts);
        },
    },
};

export default availabilityFunctions;
