/*
    options:
    - ignoreCheckedOut: boolean
*/
export function getConflicts(availability, periods, options = {}) {
    function addConflictToPeriod(data, period_id, item_id, item) {
        if (!data[period_id]) {
            data[period_id] = { items: {} };
        }
        if (!data[period_id].items[item_id]) {
            data[period_id].items[item_id] = [item];
        } else {
            data[period_id].items[item_id].push(item);
        }
    }

    function getItemAmountConflicts(item, item_id, period, period_availability) {
        let amount_checked_out = options.ignoreCheckedOut ? 0 : item.checkedOut || 0;
        const amount_needed_in_period = item.amount - amount_checked_out;
        let amount_needed = amount_needed_in_period;
        let amount_total = item.amount;
        let item_is_in_overlapping_periods = false;

        // Check if item is in overlapping periods
        // Combine those amounts
        if (Object.keys(period.overlappingPeriods || {}).length) {
            Object.keys(period.overlappingPeriods).forEach((overlapping_period_id) => {
                const overlapping_item = periods[overlapping_period_id].items.get(item_id);
                if (overlapping_item) {
                    item_is_in_overlapping_periods = true;
                    amount_needed += overlapping_item.amount - (overlapping_item.checkedOut || 0);
                    amount_total += overlapping_item.amount;
                    amount_checked_out += overlapping_item.checkedOut || 0;
                }
            });
        }

        // Check if available amount is not enough for total needed amount
        if (amount_needed > 0 && amount_needed > period_availability.amount_available) {
            // No problem in this single period, but with overlapping periods?
            // If so, check if available amount per day is enough for overlapping total amount per day
            if (item_is_in_overlapping_periods && amount_needed_in_period <= period_availability.amount_available) {
                const item_availability_per_day = Object.entries(period_availability.days).reduce((acc, [day, day_data]) => {
                    if (!acc[day]) {
                        acc[day] = day_data.amount_available;
                    }
                    return acc;
                }, {});

                const item_amount_needed_per_day = {};
                Object.entries(period.overlappingPeriods).forEach(([overlapping_period_id, overlapping_timestamps], index) => {
                    overlapping_timestamps.forEach((timestamp) => {
                        // Add current period amounts on round 1
                        if (index === 0) {
                            item_amount_needed_per_day[timestamp] = amount_needed_in_period;
                        }
                        // Add overlapping period amounts
                        const overlapping_item = periods[overlapping_period_id].items.get(item_id);
                        if (overlapping_item) {
                            if (item_amount_needed_per_day[timestamp]) {
                                item_amount_needed_per_day[timestamp] += overlapping_item.amount - (overlapping_item.checkedOut || 0);
                            } else {
                                item_amount_needed_per_day[timestamp] = overlapping_item.amount - (overlapping_item.checkedOut || 0);
                            }
                        }
                    });
                });

                // If amount needed per day is less than available amount per day, then there is no conflict
                if (Object.keys(item_amount_needed_per_day).every(timestamp => item_amount_needed_per_day[timestamp] <= item_availability_per_day[timestamp])) {
                    return false;
                }
            }

            return {
                type: 'amount',
                name: item.name,
                amountNeeded: amount_total,
                amountCheckedout: amount_checked_out,
                amountAvailable: period_availability.amount_available,
                availabilityData: period_availability,
            };
        }
        // Amount is sufficient
        return false;
    }
    const res = Object.entries(availability).reduce((conflicts, [item_id, availability_item]) => Object.entries(availability_item.periods).reduce((acc, [period_id, period_data]) => {
        const item_data = periods[period_id]?.items.get(+item_id);
        if (item_data) {
            // TODO check for serial duplications in overlapping periods
            if (item_data.serials?.size) {
                item_data.serials.forEach((serial, amount) => {
                    const available_barcode = Object.values(period_data.barcodes).find(it => it.id === serial);
                    if (!available_barcode) {
                        addConflictToPeriod(acc, period_id, +item_id, {
                            type: 'serial',
                            serial,
                            name: item_data.name,
                            amountNeeded: amount,
                            amountAvailable: 0,
                            availabilityData: period_data,
                        });
                    } else if (available_barcode.amount < amount) {
                        addConflictToPeriod(acc, period_id, +item_id, {
                            type: 'serial-in-booking',
                            serial,
                            name: item_data.name,
                            amountNeeded: amount,
                            amountAvailable: available_barcode.amount,
                            availabilityData: period_data,
                        });
                    }
                });
            }
            const amountConflict = getItemAmountConflicts(item_data, +item_id, periods[period_id], period_data);
            if (amountConflict) {
                addConflictToPeriod(acc, period_id, +item_id, amountConflict);
            }
        }

        return acc;
    }, conflicts), {});

    return res;
}

export default getConflicts;
