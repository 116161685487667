import {
    quotesGetDays, getStaggeredDiscount, quotesStaggeredRecalc, quotesStaggeredCalcOnProductChange,
} from '@/mixins/staggeredDiscounts';
import onRepairComplete from '@/mixins/repairs/repairMixin';
import { setChecklistItems } from '@/components/checklist/mixins/data';
import { availabilityStateParams } from '@/mixins/rules/availability';

const custom_client = {
    Editpane: {
        methods: {
            onRepairComplete,

            showAvailabilityWarningIfUnavailable(data) {
                return new Promise((resolve, reject) => {
                    if (!this.storeddata.availabilityState || this.storeddata.availabilityState === availabilityStateParams.INITIALIZED) {
                        alert('De beschikbaarheid is nog niet gecontroleerd. Controleer de beschikbaarheid voordat je de boeking opslaat.');
                        reject(new Error("Availability state not set"));
                        return false;
                    }

                    if (
                        [1, 2, 7, 8, 13, 10, 3].includes(data.item.status)
                        && this.tabs.list.items.icon
                        && this.tabs.list.items.icon.includes('alert')
                    ) {
                        this.$modal({
                            id: 'confirm',
                            path: 'Confirm',
                            title: this.$t('dayblocks.unavailable_items_in_booking_confirm_title'),
                            data: {
                                text: this.$t('dayblocks.unavailable_items_in_booking_error'),
                                buttonText1: this.$t('actions.cancel'),
                                buttonClass1: 'bg-accent',
                                buttonShortcuts1: ['n', 'esc'],
                            },
                        })
                            .then(() => {
                                resolve(data);
                            })
                            .catch(reject);
                    } else {
                        resolve(data);
                    }
                });
            },
        },
    },

    Fields: {
        methods: {
            quotesGetDays,
            getStaggeredDiscount,
            quotesStaggeredRecalc,
            quotesStaggeredCalcOnProductChange,
            setChecklistItems,
        },
    },

    Rules: {
        mounted() {
            this.$on('changed', this.onRuleChanged);
        },

        beforeDestroy() {
            this.$off('changed', this.onRuleChanged);
        },

        methods: {
            onRuleChanged(payload = {}) {
                if (this.config.api.table === 'bookings' && payload.action === 'add-line') {
                    // option and field are also available
                    this.$set(
                        payload.item, 'days', quotesGetDays({ data: { mainItem: this.item } }),
                    );
                    if (payload.item.days) {
                        this.$set(
                            payload.item,
                            'volume_discount',
                            this.getStaggeredDiscount(payload.item.days),
                        );
                    }
                }
            },

            getStaggeredDiscount,
        },
    },
};

export default custom_client;
